<template>
    <div>
        <div ref="trigger" v-if="filters.length" class="h-20 lg:transform-none lg:h-auto"
             :class="{ '-translate-y-1/2': !fixed }">
            <!-- MOBILE -->
            <div class="text-white text-sm flex cursor-pointer shadow-md lg:hidden"
                 :class="[fixed ? 'fixed bottom-8 inset-x-9 h-20 z-40 animate-from-bottom' : 'h-full w-full']">
                <div v-if="hasClearButton"
                     @click="clearFilters"
                     class="bg-white text-black py-3 flex flex-col items-center flex-1">
                    <icon name="trash-2-outline" class="fill-current stroke-1 stroke-black w-9 h-9 p-1" />
                    <div class="mt-1">{{ clearFiltersLabel }}</div>
                </div>
                <div class="relative bg-berry-600 py-3 flex flex-col items-center flex-1" @click="open">
                    <div class="relative w-9 h-9">
                        <icon name="options-outline" class="fill-current stroke-berry-600 w-full h-full p-1" />
                        <div
                            v-if="reactiveOverviewVars.selectedCategories.length"
                            class="bg-black border-2 border-berry-600 rounded-full w-4 h-4 absolute top-1 right-1 -translate-y-1/2 translate-x-1/2"></div>
                    </div>
                    <div class="mt-1">{{ filterLabel }}</div>
                </div>
            </div>
            <!-- DESKTOP -->
            <!-- placeholder to prevent jumping when menu gets fixed -->
            <div class="hidden h-20" :class="{ 'lg:block': fixed }"></div>
            <div class="hidden lg:block"
                 :class="[fixed ? 'fixed bottom-12 container left-0 right-0 h-20 z-40 animate-from-bottom' : 'h-full w-full']">
                <div class="bg-white shadow-md flex">
                    <div v-for="(filter, f) in filters"
                         :key="filter.filterKey"
                         @click="toggleFilter(filter.filterKey, activeFilter !== filter.filterKey)"
                         :class="{
                             'bg-gray-800 text-white': activeFilter === filter.filterKey,
                             'border-r': hasExtraFilters || hasClearButton || f + 1 < filters.length
                         }"
                         class="px-8 py-6 border-gray-400 flex-1 cursor-pointer transition-colors">
                        <div class="text-sm text-gray-600">
                            {{
                                activeFilter === filter.filterKey || selectedCategories(filter).length === 0 ? chooseLabel : filter.name
                            }}
                        </div>
                        <div>
                            {{
                                selectedCategories(filter).length && activeFilter !== filter.filterKey ? selectedCategories(filter).join(', ') : filter.name
                            }}
                        </div>
                    </div>
                    <div v-if="hasExtraFilters"
                         @click="toggleExtraFilters"
                         :class="{ 'bg-gray-800 text-white': showExtraFilters }"
                         class="group py-6 px-9 flex flex-col justify-center items-center cursor-pointer border-r border-gray-400 transition-colors hover:text-gray-800 hover:bg-gray-200">
                        <div class="relative w-8 h-8">
                            <icon name="options-outline" class="fill-current w-full h-full group-hover:stroke-gray-200"
                                  :class="[showExtraFilters ? 'stroke-gray-800' : 'stroke-white']" />
                            <div
                                :class="[showExtraFilters ? 'border-gray-800' : 'border-white']"
                                class="bg-berry-600 rounded-full transition-colors w-4 h-4 absolute top-1 right-1 border-2 -translate-y-1/2 translate-x-1/2 group-hover:border-gray-200"></div>
                        </div>
                    </div>
                    <div v-if="hasClearButton"
                         @click="clearFilters"
                         class="group py-4 px-9 flex flex-col justify-center items-center cursor-pointer text-sm transition-colors lg:shrink-0 lg:w-36 hover:bg-gray-100">
                        <div class="w-8 h-8 p-px">
                            <icon name="trash-2-outline"
                                  class="fill-current stroke-1 stroke-black w-full h-full transition-all translate-y-2 group-hover:translate-y-0" />
                        </div>
                        <div
                            class="text-center opacity-0 whitespace-nowrap transition-all translate-y-2 group-hover:translate-y-0 group-hover:opacity-100">
                            {{ clearFiltersLabel }}
                        </div>
                    </div>
                    <div
                        class="group bg-berry-600 py-4 px-9 flex flex-col cursor-pointer justify-center text-white items-center text-sm transition-colors lg:shrink-0 lg:w-36 hover:bg-berry-700"
                        @click="close">
                        <div class="w-8 h-8 p-px">
                            <icon name="search"
                                  class="fill-current w-full h-full transition-all translate-y-2 group-hover:translate-y-0" />
                        </div>
                        <div
                            class="text-center opacity-0 whitespace-nowrap transition-all translate-y-2 group-hover:translate-y-0 group-hover:opacity-100">
                            {{ showResultsLabel }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MOBILE -->
        <Transition name="fade">
            <div v-if="isOpen"
                 class="bg-black/60 fixed top-0 left-0 h-screen w-screen z-50">
                <Transition name="slide-up">
                    <div v-if="isVisible"
                         class="bg-white px-9 pt-5 pb-28 absolute bottom-0 left-0 right-0 overflow-y-auto overflow-x-hidden h-screen-90">
                        <div @click="close"
                             class="w-14 h-14 rounded-full border border-black p-4 flex justify-center items-center ml-auto mb-12">
                            <icon name="close" class="h-6 w-6" />
                        </div>
                        <div class="text-3xl mb-14 lg:hidden">{{ filterHeadlineLabel }}</div>
                        <blog-accordion v-for="filter in filters" :key="`${filter.filterKey}-mobile`"
                                        @toggle="$event => toggleFilter(filter.filterKey, $event)"
                                        :title="filter.name" group="blog">
                            <template #title>
                                <div class="max-w-accordion">
                                    <div class="text-xl">{{ filter.name }}</div>
                                    <div v-if="selectedCategories(filter).length && activeFilter !== filter.filterKey"
                                         class="flex">
                                        <div
                                            class="mt-1.5 w-2.5 h-2.5 rounded-full bg-berry-600 shrink-0 mr-2"></div>
                                        {{ selectedCategories(filter).join(', ') }}
                                    </div>
                                </div>
                            </template>
                            <div class="flex -ml-2 flex-wrap" v-if="visibleCategories">
                                <div v-for="cat in visibleCategories.categories"
                                     @click="toggleCategory(cat.key)"
                                     :key="`${filter.filterKey}:${cat.key}-mobile`"
                                     :class="[ catStyle(cat.key), { 'pointer-events-none opacity-50': !hasItems(cat.key, filter.filterKey) }]"
                                     class="text-sm py-0.5 px-2 ml-2 mb-2 border transition-colors">
                                    {{ cat.name }}
                                </div>
                            </div>
                        </blog-accordion>
                        <blog-accordion v-if="hasExtraFilters"
                                        no-border
                                        @toggle="toggleExtraFilters"
                                        :title="extraFiltersLabel"
                                        group="blog">
                            <template #title>
                                <div class="max-w-accordion">
                                    <div class="text-xl">{{ extraFiltersLabel }}</div>
                                    <div v-if="reactiveOverviewVars.unknownFilters.length && !showExtraFilters"
                                         class="flex">
                                        <div
                                            class="mt-1.5 w-2.5 h-2.5 rounded-full bg-berry-600 shrink-0 mr-2"></div>
                                        {{
                                            reactiveOverviewVars.unknownFilters.map(x => x.categories.map(c => c.label).join(', ')).join(', ')
                                        }}
                                    </div>
                                </div>
                            </template>
                            <div class="flex -ml-2 flex-wrap" v-if="visibleCategories">
                                <div v-for="cat in visibleCategories.categories"
                                     @click="toggleCategory(cat.key)"
                                     :key="`other:${cat.key}-mobile`"
                                     :class="{ 'bg-black text-white': isSelected(cat.key) }"
                                     class="text-sm py-0.5 px-2 ml-2 mb-2 border border-black transition-colors">
                                    {{ cat.name }}
                                </div>
                            </div>
                        </blog-accordion>
                        <div class="flex fixed bottom-0 left-0 right-0 h-20 text-sm lg:hidden">
                            <div v-if="hasClearButton"
                                 @click="clearFilters"
                                 class="bg-gray-100 text-black py-4 flex flex-col items-center flex-1">
                                <icon name="trash-2-outline" class="fill-current stroke-1 stroke-black w-9 h-9" />
                                <div>{{ clearFiltersLabel }}</div>
                            </div>
                            <div class="bg-berry-600 py-3 flex flex-col text-white items-center flex-1"
                                 @click="close">
                                <icon name="search" class="fill-current stroke-berry-600 w-9 h-9" />
                                <div class="mt-0.5 pt-px">{{ showResultsLabel }}</div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </Transition>
        <!-- DESKTOP -->
        <div class="hidden z-40 lg:block" :class="{ 'fixed bottom-32 container left-0 right-0': fixed }">
            <collapse-transition>
                <div v-if="activeFilter.length || showExtraFilters"
                     :class="[fixed ? 'border-b-4' : 'border-t-4']"
                     class="px-11 py-14 bg-white border-gray-800">
                    <template v-if="visibleCategories">
                        <div class="text-2xl mb-4">{{ visibleCategories.title }}</div>
                        <div class="flex -ml-2 flex-wrap">
                            <div v-for="cat in visibleCategories.categories"
                                 :key="cat.id"
                                 @click="toggleCategoryAndClose(cat.key)"
                                 :class="[catStyle(cat.key), { 'pointer-events-none opacity-50': !hasItems(cat.key, visibleCategories.filter) }]"
                                 class="text-sm py-0.5 px-2 ml-2 mb-2 border cursor-pointer transition-colors">
                                {{ cat.name }}
                            </div>
                        </div>
                    </template>
                </div>
            </collapse-transition>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Icon from '../base/Icon.vue';
import AsyncOverviewFilter from '../overview/AsyncOverviewFilter.vue';

@Component({
    components: { Icon }
})
export default class BlogFilterMenu extends Vue {
    @Ref('trigger') trigger: HTMLElement;
    @Prop({ required: true }) filterHeadlineLabel: string;
    @Prop({ required: true }) clearFiltersLabel: string;
    @Prop({ required: true }) showResultsLabel: string;
    @Prop({ required: true }) findBlogsLabel: string;
    @Prop({ required: true }) chooseLabel: string;
    @Prop({ required: true }) filterLabel: string;
    @Prop({ required: true }) otherFilterLabel: string;
    @Prop({ required: true }) extraFiltersLabel: string;
    @Prop({ default: false }) allResultsLoaded: boolean;
    @Prop() filters: AsyncOverviewFilter[];
    @Inject({ default: () => () => undefined }) toggleCategory: (categoryKey: string, parent?: string, remove?: boolean) => void;
    @Inject({ default: () => () => false }) isSelected: (categoryKey: string) => boolean;
    @Inject({ default: () => () => undefined }) scrollToTop: () => void;

    @Inject({
        default: () => ({
            categoriesUrl: '',
            selectedCategories: [[]],
            unknownFilters: []
        })
    }) reactiveOverviewVars: {
        categoriesUrl: string;
        selectedCategories: string[];
        unknownFilters?: {
            id: string;
            name: string;
            label: string;
            categories: { id: string; name: string; label: string }[];
        }[];
    };

    fixed = false;
    isOpen = false;
    isVisible = false;
    showExtraFilters = false;
    activeFilter = '';
    loading = false;
    controller = new AbortController();
    // available categories with the current filter selection (others will be deselected)
    availableCategories: { [id: string]: number } = {};
    // all available categories (others will be hidden)
    allAvailableCategories: { [id: string]: number } = {};

    footerScrollTrigger = null;

    created() {
        gsap.registerPlugin(ScrollTrigger);
    }

    mounted() {
        this.$nextTick(() => {
            ScrollTrigger.create({
                trigger: this.trigger,
                start: 'bottom top',
                onEnter: () => {
                    this.fixed = true;
                    this.close();
                },
                onEnterBack: () => {
                    this.fixed = false;
                    this.close();
                }
            });
        });
    }

    open() {
        this.isOpen = true;
        setTimeout(() => {
            this.isVisible = true;
        }, 100);
        // mobile menu is fullscreen so disable scrolling in the background
        document.body.classList.add('overflow-hidden', 'lg:overflow-auto');
    }

    close() {
        this.activeFilter = '';
        this.isVisible = false;
        setTimeout(() => {
            this.isOpen = false;
        }, 100);
        // mobile menu is fullscreen so re-enable scrolling in the background
        document.body.classList.remove('overflow-hidden', 'lg:overflow-auto');
    }

    toggleFilter(filter: string, open: boolean) {
        this.showExtraFilters = false;
        this.activeFilter = open ? filter : '';
    }

    toggleCategoryAndClose(key: string) {
        this.toggleCategory(key);
        this.showExtraFilters = false;
        this.scrollToTop();
    }

    selectedCategories(filter: AsyncOverviewFilter): string[] {
        return filter.categories.filter(x => this.isSelected(x.categoryKey)).map(x => x.name);
    }

    isAvailable(categoryKey: string) {
        if (!Object.keys(this.allAvailableCategories).length) {
            return true;
        }
        if (Object.prototype.hasOwnProperty.call(this.allAvailableCategories, categoryKey)) {
            return this.allAvailableCategories[categoryKey] > 0;
        }
        return false;
    }

    // used to disable filters if the lead to an empty result
    hasItems(categoryKey: string, filterKey: string): boolean {
        // currently selected filter is always enabled (in order to deselect it)
        if (!Object.keys(this.availableCategories).length || this.reactiveOverviewVars.selectedCategories.includes(categoryKey)) {
            return true;
        }
        // parent filter
        const filter = this.filters.find(f => f.filterKey === filterKey);
        if (filter) {
            // if no other filter has selected categories, all the current ones are enabled
            if (this.reactiveOverviewVars.selectedCategories.every(cat => filter.categories.map(x => x.categoryKey).includes(cat))) {
                return true;
            }
        }
        if (Object.prototype.hasOwnProperty.call(this.availableCategories, categoryKey)) {
            // enable filter if it leads to at least 1 result
            return this.availableCategories[categoryKey] > 0;
        }
        return false;
    }

    clearFilters() {
        this.showExtraFilters = false;
        this.filters.forEach(filter => {
            filter.categories.forEach(cat => {
                if (this.isSelected(cat.categoryKey)) {
                    this.toggleCategory(cat.categoryKey);
                }
            });
        });
        this.reactiveOverviewVars.unknownFilters.forEach(filter => {
            filter.categories.forEach(cat => {
                this.toggleCategory(cat.name);
            });
        });
        this.close();
    }

    toggleExtraFilters() {
        this.activeFilter = '';
        this.showExtraFilters = !this.showExtraFilters;
    }

    catStyle(categoryKey: string): string {
        return 'border-black hover:bg-black hover:text-white' + (this.isSelected(categoryKey) || this.showExtraFilters ? ' bg-black text-white' : '');
    }

    get hasExtraFilters(): boolean {
        return this.reactiveOverviewVars.unknownFilters.length > 0;
    }

    get hasClearButton(): boolean {
        return this.filters.some(filter => this.selectedCategories(filter).length > 0) || this.reactiveOverviewVars.unknownFilters.length > 0;
    }

    get selectedFilter(): AsyncOverviewFilter {
        return this.filters.find(x => x.filterKey === this.activeFilter);
    }

    get visibleCategories(): {
        title: string;
        filter?: string;
        categories: { id: string; key: string; name: string }[];
        } {
        if (this.showExtraFilters) {
            return {
                title: this.otherFilterLabel,
                categories: this.reactiveOverviewVars.unknownFilters.reduce((a, x) => [...x.categories.map(c => ({
                    id: c.id,
                    key: c.name,
                    name: c.label
                })), ...a], [])
            };
        }
        if (this.selectedFilter) {
            return {
                title: `${this.findBlogsLabel} ${this.selectedFilter.name}`,
                filter: this.selectedFilter.filterKey,
                categories: this.selectedFilter.categories.filter(x => this.isAvailable(x.categoryKey)).map(x => ({
                    id: x.categoryId,
                    key: x.categoryKey,
                    name: x.name
                }))
            };
        }
    }

    // hide filter bar when find footer
    footerScrollTriggerInicialize() {
        const footerElement = document.querySelector('footer');
        if (this.footerScrollTrigger) {
            this.footerScrollTrigger.kill();
        }
        this.footerScrollTrigger = ScrollTrigger.create({
            trigger: footerElement,
            start: 'top bottom',
            onEnter: () => {
                this.fixed = false;
                this.close();
            },
            onLeaveBack: () => {
                this.fixed = true;
                this.close();
            }
        });
    }

    // when the selected filters change fetch the available categories again
    @Watch('reactiveOverviewVars', { deep: true })
    async getAvailableCategories() {
        if (this.loading) {
            this.controller.abort();
            this.loading = false;
            this.controller = new AbortController();
        }
        try {
            // const url = this.reactiveOverviewVars.categoriesUrl.replace(/\?.*$/, '');
            // const filterString = `?filter=${this.reactiveOverviewVars.selectedFilters.map(f => `${f.filter}:${f.categories.join(',')}`).join(';')}`;
            // const categoriesResponse = await fetch(url + filterString, { signal: this.controller.signal });
            this.loading = true;
            const categoriesResponse = await fetch(this.reactiveOverviewVars.categoriesUrl, { signal: this.controller.signal });
            if (categoriesResponse.status === 200) {
                this.availableCategories = await categoriesResponse.json();
            }
            const allCategoriesResponse = await fetch(this.reactiveOverviewVars.categoriesUrl.replace(/\?.*$/, ''), { signal: this.controller.signal });
            if (allCategoriesResponse.status === 200) {
                this.allAvailableCategories = await allCategoriesResponse.json();
            }
            this.loading = false;
        } catch (_) {
            this.loading = false;
        }
    }

    // hide filter bar when have all results and find footer
    @Watch('allResultsLoaded')
    allResultsLoadedChange(newValue: boolean, oldValue: boolean) {
        if (newValue === true) {
            this.footerScrollTriggerInicialize();
        } else if (this.footerScrollTrigger) {
            this.footerScrollTrigger.kill();
        }
    }
}
</script>
