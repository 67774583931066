<template>
    <div>
        <div @click="onOpen" :class="classes">
            <div class="flex relative px-8 lg:px-10 h-full flex-col-reverse justify-end pb-8"
                 :class="[{ 'min-h-150': item.layout === 'textBig' }, index % 2 === 0 ? 'lg:h-128' : 'lg:h-112']">
                <div class="mt-8 lg:flex lg:flex-col lg:flex-1">
                    <div class="mb-2">
                        <div v-if="item.kicker"
                             class="tracking-wider uppercase">{{ item.kicker }}
                        </div>
                    </div>
                    <h3 class="mb-4 text-2xl lg:text-3xl lg:line-clamp-2">
                        {{ item.title }}</h3>
                    <div class="border-t border-black pt-3 mt-auto"></div>
                </div>
                <div class="-mx-10">
                    <div class="grow relative w-full pt-5/3 overflow-hidden">
                        <img :src="item.image ? item.image : `${$contextPath}/.resources/gft/webresources/img/search-placeholder.webp`"
                             :alt="item.imageAlt ? item.imageAlt : 'case-image'"
                             class="top-0 left-0 object-cover h-full w-full transition duration-300 ease-in-out absolute group-hover:scale-105">
                    </div>
                </div>
            </div>
        </div>
        <Transition name="fade">
            <div v-if="open"
                 :class="popupClasses"
                 class="overflow-y-auto overflow-x-hidden fixed bottom-0 left-0 z-40 h-screen w-screen bg-black/30 backdrop-blur transition-opacity duration-500">
                <Transition name="slide-bottom">
                    <div v-if="visible" class="h-full transition-transform duration-500 ease-in-out">
                        <div class="flex flex-col min-h-screen cursor-auto">
                            <div class="h-24 w-full bg-black/20" @click="close">
                            </div>
                            <div
                                class="bg-white border-t-5 border-berry-600 grow px-7 pb-20 lg:p-0 lg:flex">
                                <div class="mx-auto mt-3 mb-20 w-24 h-2 bg-gray-400 rounded-full lg:hidden"></div>
                                <div
                                    class="lg:grid lg:grid-cols-12 lg:gap-x-16 lg:flex-col lg:min-h-full lg:grid-rows-28-auto">
                                    <!-- left image on desktop-->
                                    <div
                                        class="hidden lg:block lg:col-span-2 lg:row-span-3 lg:col-start-1 lg:row-start-1">
                                        <img v-if="item.image" :src="item.image"
                                             :alt="item.imageAlt ? item.imageAlt : 'case-image'"
                                             class="object-cover max-w-none w-full h-full">
                                    </div>
                                    <div
                                        class="lg:col-span-8 lg:row-start-2 lg:col-start-3 lg:grid lg:grid-cols-12 lg:gap-4">
                                        <!-- kicker and title -->
                                        <div class="lg:col-span-6">
                                            <div v-if="item.kicker" class="tracking-wider uppercase mb-2">
                                                {{ item.kicker }}
                                            </div>
                                            <h3 class="mb-12 text-2xl lg:text-3xl lg:mb-8">{{ item.title }}</h3>
                                        </div>
                                        <!-- text and media -->
                                        <div class="lg:col-span-6 lg:row-start-2">
                                            <div v-if="item.text" class="mb-8 simple-bullets"
                                                 v-html="item.text"></div>
                                        </div>
                                        <!-- links -->
                                        <div class="lg:col-span-5 lg:row-start-2 lg:col-start-8">
                                            <div class="mt-16 lg:-mt-6">
                                                <template v-for="_link in item.links">
                                                    <a v-if="_link.url" :key="`${item.slug}#link-${_link.url}`"
                                                    :aria-labelledby="_link.label"
                                                    :href="_link.url" target="_blank"
                                                    class="relative border-b border-gray-400 w-full flex items-center group/link py-4"
                                                    :class="{
                                                        'lg:py-6': item.links.length < 3,
                                                        'lg:py-5': item.links.length >= 3
                                                    }">
                                                        <icon name="arrow"
                                                              class="w-5 h-5 -ml-2 transition-colors fill-current mr-4" />
                                                        <div class="break-words max-w-4/5"
                                                             :class="{
                                                                'text-2xl': item.links.length < 5,
                                                                'text-lg': item.links.length == 5}">
                                                            {{ _link.label }}
                                                        </div>
                                                        <div class="absolute -bottom-px left-0 w-full h-0.5 bg-current origin-left scale-x-0 transition-transform duration-500 group-hover/link:scale-x-100"></div>
                                                    </a>
                                                </template>
                                                <!-- contact link -->
                                                <a :href="`${baseUrl}/Contact-gft`" target="_blank"
                                                   :aria-labelledby="caseOverviewContactLabel"
                                                   class="relative border-b border-gray-400 w-full flex items-center group/link py-4 mb-8"
                                                   :class="{
                                                        'lg:py-6': item.links.length < 3,
                                                        'lg:py-5': item.links.length >= 3
                                                    }">
                                                    <icon name="arrow"
                                                          class="w-5 h-5 -ml-2 transition-colors fill-current mr-4" />
                                                    <div class="break-words max-w-4/5"
                                                         :class="{
                                                                'text-2xl': item.links.length < 5,
                                                                'text-lg': item.links.length == 5}">
                                                        {{ caseOverviewContactLabel }}
                                                    </div>
                                                    <div class="absolute -bottom-px left-0 w-full h-0.5 bg-current origin-left scale-x-0 transition-transform duration-500 group-hover/link:scale-x-100"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="mt-16 lg:col-span-8 lg:row-start-3 lg:mb-14 lg:border-b lg:border-current lg:pb-4 lg:mt-0 lg:self-end">
                                        <div v-if="item.media || item.infotitle || item.infotext"
                                             class="lg:grid lg:grid-cols-12 lg:gap-4">
                                            <div v-if="item.media.length" class="lg:col-span-7 lg:b-8">
                                                <gft-swiper :gap="8"
                                                            class="relative lg:h-auto"
                                                            slide-classes="w-full lg:w-slide-1/3"
                                                            container-classes="overflow-visible">
                                                    <template v-for="(media, m) in item.media">
                                                        <div v-if="media.type === 'image' && media.image && media.full"
                                                             :key="`${item.slug}#media-${m}`">
                                                            <img :src="media.image"
                                                                 :alt="media.imageAlt ? media.imageAlt : 'case-image'"
                                                                 class="object-cover w-full"
                                                                 v-lightbox :data-original="media.full"
                                                                 data-lb-style="small">
                                                        </div>
                                                        <div v-else :key="`${item.slug}#media-${m}`">
                                                            <div class="relative h-0 pt-5/3 self-center">
                                                                <template v-if="media.type === 'vimeoLink'">
                                                                    <div
                                                                        class="overlay absolute top-0 left-0 w-full h-full z-10"
                                                                        v-lightbox="media.vimeoLink"
                                                                        :data-lb-vimeolink="media.vimeoLink"
                                                                        data-lb-style="small">
                                                                        <template v-if="media.thumbnail">
                                                                            <img :src="media.thumbnail"
                                                                                 :alt="media.thumbnailAlt ? media.thumbnailAlt : 'case-image'"
                                                                                 class="object-contain w-full">
                                                                            <div
                                                                                class="flex absolute top-0 left-0 justify-center items-center w-full h-full text-white bg-black/40">
                                                                                <div
                                                                                    class="flex justify-center items-center w-16 h-16 rounded-full border border-white cursor-pointer lg:w-36 lg:h-36">
                                                                                    <icon name="arrow-right-outline"
                                                                                          class="w-11 h-11 fill-current" />
                                                                                </div>
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <!-- if have the thumb doesn't need the youtube background-->
                                                                    <gft-video
                                                                        v-if="!media.thumbnail"
                                                                        :link="media.vimeoLink"
                                                                        class="object-contain absolute w-full h-full top-0 left-0 object-left"
                                                                    ></gft-video>
                                                                </template>
                                                                <!-- youtube-->
                                                                <div v-else-if="media.type === 'youtubeId'"
                                                                     class="overlay absolute top-0 left-0 w-full h-full z-10"
                                                                     v-lightbox="media.youtubeId"
                                                                     :data-lb-youtubeid="media.youtubeId"
                                                                     data-lb-style="small">
                                                                    <template v-if="media.thumbnail">
                                                                        <img :src="media.thumbnail"
                                                                             :alt="media.thumbnailAlt ? media.thumbnailAlt : 'case-image'"
                                                                             class="object-contain w-full">
                                                                        <div
                                                                            class="flex absolute top-0 left-0 justify-center items-center w-full h-full text-white bg-black/40">
                                                                            <icon name="youtube"
                                                                                  class="w-11 h-11 fill-current" />
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                                <!-- if have the thumb doesn't need the youtube background-->
                                                                <iframe
                                                                    v-if="!media.thumbnail"
                                                                    allowfullscreen
                                                                    mozallowfullscreen
                                                                    msallowfullscreen
                                                                    oallowfullscreen
                                                                    webkitallowfullscreen
                                                                    class="object-contain absolute w-full h-full top-0 left-0 object-left"
                                                                    :src="`https://www.youtube.com/embed/${media.youtubeId}?playsinline=1&showinfo=0&rel=0&enablejsapi=1`"
                                                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;"
                                                                ></iframe>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template #pagination="{ index, total, go, uid }">
                                                        <div class="relative mb-12 w-full lg:mb-0 lg:hidden">
                                                            <div class="absolute space-x-1 w-full text-center lg:pt-4">
                                                                <div v-for="i in total"
                                                                     :key="uid + '_' + i"
                                                                     :class="[i === index ? 'bg-black' : 'bg-gray-400']"
                                                                     @click="go(i - 1)"
                                                                     class="w-6 h-0.5 transition-colors inline-block cursor-pointer"></div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </gft-swiper>
                                            </div>
                                            <!-- grey box-->
                                            <div v-if="item.infotitle || item.infotext"
                                                 class="lg:col-span-5 lg:col-start-8">

                                                <div class="px-5 py-7 bg-gray-200 relative lg:px-8 lg:py-10">
                                                    <div
                                                        class="absolute top-2 bottom-5 left-3 right-0 w-full bg-left-top bg-no-repeat bg-hero bg-double-angle"></div>
                                                    <div class="relative">
                                                        <div v-if="item.infotitle" class="uppercase">
                                                            {{ item.infotitle }}
                                                        </div>
                                                        <div v-if="item.infotext">{{ item.infotext }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- categories -->
                                        <div v-if="item.categories.length" class="flex flex-wrap lg:mt-8">
                                            <div v-for="category in item.categories"
                                                 :key="`${category.name}-${item.slug}`"
                                                 class="py-1 px-2 border border-current w-fit mr-2 mt-2 text-sm">
                                                {{ category.label }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- close -->
                                    <div @click="close"
                                         class="cursor-pointer lg:row-start-1 lg:col-start-1 lg:col-span-12">
                                        <div class="container-wide hidden lg:block">
                                            <div class="flex justify-end">
                                                <div
                                                    class="mt-11 border p-2 rounded-full border-black w-14 h-14 inline-flex justify-center items-center hover:text-white hover:bg-black">
                                                    <icon name="close" class="w-6 h-6 transition-colors fill-current" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </Transition>
        <!-- close button mobile -->
        <Transition name="fade">
            <div
                v-if="open"
                @click="close"
                class="fixed z-50 bg-white border-t-5 border-gray-100 bottom-0 left-0 w-full lg:hidden">
                <div class="mx-auto w-fit text-berry-600">
                    <div class="flex items-center my-3">
                        <icon name="chevron-left" class="-mt-px w-6 h-7 fill-current" />
                        <span class="ml-1.5 font-bold">{{ backLabel }}</span>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';
import { ResultItem } from './overview/AsyncOverview.vue';

@Component({
    components: { Icon }
})
export default class UseCasePopup extends Vue {
    @Prop({}) item: ResultItem;
    @Prop({}) classes: string;
    @Prop({}) popupClasses: string;
    @Prop({}) url: string;
    @Prop({}) baseUrl: string;
    @Prop({}) index: number;
    @Prop({}) backLabel: string;
    @Prop({}) caseOverviewContactLabel: string;
    // pop up variables
    open = false;
    visible = false;
    scrollPosition = 0;

    mounted() {
        if (window.location.hash.indexOf(`caseopen=${this.url}`) !== -1) {
            this.open = true;
            this.visible = true;
            this.scrollPosition = window.scrollY;
            document.body.classList.add('fixed');
            document.body.style.top = `-${this.scrollPosition}px`;
            const scrollbarWidth = getComputedStyle(document.documentElement).getPropertyValue('--scrollbar-width');
            document.body.style.marginRight = scrollbarWidth;
            document.body.style.width = '100%';
        }
    }

    // pop up functions
    onOpen() {
        // Store the current scroll position
        this.scrollPosition = window.scrollY;

        this.open = true;
        setTimeout(() => {
            this.visible = true;
        }, 300);
        document.body.classList.add('fixed');
        document.body.style.top = `-${this.scrollPosition}px`;
        // put margin right with the width of the scroll bar
        const scrollbarWidth = getComputedStyle(document.documentElement).getPropertyValue('--scrollbar-width');
        document.body.style.marginRight = scrollbarWidth;
        document.body.style.width = '100%';
        // update hash
        if (this.url) {
            const currentHash = window.location.hash;
            const newHash = currentHash ? `${currentHash}&caseopen=${this.url}` : `#caseopen=${this.url}`;
            window.location.hash = newHash;
        }
        // push to data layer
        if (window.dataLayer) {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({
                event: 'openUseCase',
                name: this.url
            });
        }
    }

    close() {
        this.visible = false;
        setTimeout(() => {
            this.open = false;
            window.scrollTo(0, this.scrollPosition);
        }, 300);
        document.body.classList.remove('fixed');
        document.body.style.top = 'unset';
        // remove margin right with the width of the scroll bar
        document.body.style.marginRight = '0';

        // update hash
        if (this.url) {
            const currentHash = window.location.hash;
            const targetHash = `caseopen=${this.url}`;

            if (currentHash.indexOf(`${targetHash}`) !== -1) {
                const newHash = currentHash.replace(`&${targetHash}`, '').replace(`${targetHash}`, '');
                window.location.hash = newHash;
            }
        }
    }
}
</script>
