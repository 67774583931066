<template>
    <div>
        <button v-on:click="isHidden = !isHidden" class="text-m font-bold">{{ question }}</button>
        <div v-if="!isHidden" v-html="answer"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class Faq extends Vue {
    @Prop() question: string;
    @Prop() answer: string;
    isHidden = true;
}

export default Vue.component('faq', Faq);

</script>
