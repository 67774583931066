<template>
    <div>
        <div v-if="textReadMore">
            <div class="line-clamp-4 overflow-hidden" v-show="!visible">
                <slot />
            </div>
        </div>
        <div v-else>
            <div>
                <slot name="showed"></slot>
            </div>
        </div>
        <slot name="openbutton" :toggle="toggle" :visible="visible">
            <button @click="toggle" :class="[{'hidden' : visible}]" class="pt-4">
                <icon name="arrow"
                    class="inline-block w-4 h-4 align-middle rotate-90 fill-current" />
                <span class="text-current">
                {{ clamp }}
                </span>
            </button>
        </slot>
        <collapse-transition v-show="visible" :duration="0">
            <div>
                <slot v-if="textReadMore"></slot>
                <slot v-else name="hidden"/>
                <slot name="closebutton" :toggle="toggle" :visible="visible">
                    <button @click="toggle" class="pt-4">
                        <icon name="arrow"
                            class="inline-block w-4 h-4 align-middle -rotate-90 fill-current" />
                        <span class="text-current" :class="{'hidden' : !visible}">
                        {{ less }}
                        </span>
                    </button>
                </slot>
            </div>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';

import Icon from './base/Icon.vue';

@Component({
    components: { Icon, CollapseTransition }
})
class Readmore extends Vue {
    @Prop({ default: true }) textReadMore: boolean;
    @Prop() text: string;
    @Prop() clamp: string;
    @Prop() less: string;
    @Prop({ default: false }) open!: boolean;

    visible = false;

    mounted() {
        this.visible = this.open;
    }

    toggle() {
        this.visible = !this.visible;
    }
}

export default Vue.component('read-more', Readmore);

</script>
