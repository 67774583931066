<template>
    <div ref="el" class="frc-captcha" :class="classes" :data-sitekey="sitekey"></div>
</template>

<script lang="ts">
import { WidgetInstance } from 'friendly-challenge';

export default {
    name: 'FriendlyCaptcha',
    props: {
        sitekey: {
            type: String,
            required: true
        },
        classes: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            widget: null
        };
    },
    mounted() {
        if (this.$refs.el) {
            this.widget = new WidgetInstance(this.$refs.el, {
                startMode: 'focus',
                doneCallback: this.doneCallback,
                errorCallback: this.errorCallback
            });
        }
    },
    beforeUnmount() {
        if (this.widget) {
            this.widget.destroy();
        }
    },
    methods: {
        doneCallback() {
            this.$emit('solve', true);
        },
        errorCallback() {
            this.$emit('solve', false);
        }
    }
};
</script>
