<template>
    <div>
        <div class="container">
            <div class="flex justify-between items-center border-b border-current">
                <input class="flex-1 pb-2 text-2xl bg-transparent outline-none placeholder-gray-400"
                       :placeholder="placeholder"
                       ref="input"
                       @input="search"
                       v-model="searchValue">
                <icon name="search-outline"
                      :class="[searchValue.length ? 'text-current' : 'text-gray-600']"
                      class="shrink-0 w-6 h-6 fill-current lg:transition-colors lg:group-hover:text-black" />
            </div>
        </div>
        <div class="container mt-20 mb-4 uppercase tracking-wider" v-if="result && result.total">
            {{ result.total }} {{ resultsLabel }}
        </div>
        <div v-if="error" class="container mt-16">{{ errorMessage }}</div>
        <search-results v-else-if="result" :results="results" :no-results-message="noResultsMessage" />
        <div v-if="loading" class="container py-20 text-center">
            <throbber />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Icon from './base/Icon.vue';
import { debounce } from 'lodash';
import axios from 'axios';
import SearchResults from './SearchResults.vue';
import Throbber from './base/Throbber.vue';

interface SearchResultItem {
    image: string;
    title: string;
    text: string;
    link: string;
}

export interface SearchResult {
    results: SearchResultItem[];
    total: number;
    offset: number;
    limit: number;
}

const SEARCH_ENDPOINT = '/.rest/api/v1/search';
const LIMIT = 10;

@Component({
    components: { Icon, SearchResults, Throbber }
})
export default class GftSearch extends Vue {
    @Prop({ required: true }) resultsLabel: string;
    @Prop({ required: true }) placeholder: string;
    @Prop({ required: true }) errorMessage: string;
    @Prop({ required: true }) noResultsMessage: string;

    search = debounce(() => this.onSearch(false), 350);
    searchValue = '';
    placeholderImage = `${this.$contextPath}/.resources/gft/webresources/img/search-placeholder.webp`;
    result: SearchResult = null;
    results: SearchResultItem[] = [];
    endReached = false;
    offset = 0;
    loading = false;
    error = false;

    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const query = urlParams.get('query');
        if (query) {
            this.searchValue = query;
            this.onSearch(false);
        }

        document.addEventListener('scroll', this.onScroll);
    }

    beforeUnmount() {
        document.removeEventListener('scroll', this.onScroll);
    }

    onScroll() {
        // skip while request is active
        if (this.loading) {
            return;
        }
        if ((this.$el.getBoundingClientRect().bottom - window.innerHeight) < 0) {
            if (!this.endReached) {
                this.endReached = true;
            }
        } else {
            if (this.endReached) {
                this.endReached = false;
            }
        }
    }

    async onSearch(append = false) {
        if (!append) {
            this.offset = 0;
        }
        this.loading = true;
        const sanitizedInput = this.searchValue.replace(/[^a-zA-Z0-9\s-_.]/g, '').replace(/[-_]/g, ' ').trim();
        if (sanitizedInput.length > 1) {
            if (history.pushState) {
                const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?query=${sanitizedInput}`;
                window.history.pushState({ path: newUrl }, '', newUrl);
            }
            try {
                this.error = false;
                const response = await axios.get<SearchResult>(`${this.$contextPath}${SEARCH_ENDPOINT}/${this.$site}/${this.$lang}?query=${sanitizedInput}&limit=${LIMIT}&offset=${this.offset}`);
                if (response.status === 200) {
                    this.result = response.data;
                    if (append) {
                        this.results.push(...this.result.results);
                    } else {
                        this.results = this.result.results;
                    }
                } else {
                    this.result = null;
                    this.results = [];
                }
            } catch (e) {
                this.error = true;
            }
        } else {
            this.result = null;
            this.results = [];
            this.error = false;
        }
        this.loading = false;
    }

    @Watch('endReached')
    onToggleScroll() {
        if (this.endReached) {
            if (this.result && this.result.total > this.results.length) {
                this.offset += LIMIT;
                this.onSearch(true);
            }
        }
    }
}
</script>
