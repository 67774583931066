<template>
    <div class="accordion" :class="compClass">
        <slot name="header" :open="visible" :clickToogle="toggle">
            <button class="px-9 w-full text-left focus:outline-none "
                    :class="[{
                        'lg:border-b':                      !visible && styling === 'default',
                        'lg:border-b border-gray-400':      !visible && (styling === 'semi' || styling === 'contrast'),
                        'bg-white    hover:bg-gray-200':          !visible && !location && styling === 'default',
                        'bg-gray-200 hover:bg-white':             !visible && !location && styling === 'semi',
                        'bg-black    hover:bg-gray-800': !visible && !location && styling === 'contrast',
                        [darkTextColor]:    (!visible && (!location)) && (styling === 'semi' || styling === 'default'),
                        [lightTextColor]:   (!visible && (!location)) && styling === 'contrast',
                        [lightTextColor]:   (visible && (!location))  && (styling === 'semi' || styling === 'default'),
                        [darkTextColor]:    (visible && (!location))  && styling === 'contrast',
                        'bg-black':     visible && !location && (styling === 'semi' || styling === 'default'),
                        'bg-white':     visible && !location && styling === 'contrast',
                    }]"
                    @click="toggle">
                <span class="flex content-between items-center py-7">
                    <slot name="title">
                        <span class="max-w-accordion">{{ title }}</span>
                    </slot>
                    <icon :name="[ visible ? 'minus' : 'plus']"
                          class="shrink-0 ml-auto w-5 h-5 fill-current"
                          :class="[{
                              [lightTextColor]:     !visible    && color === 'contrast',
                              [darkTextColor]:      visible     && color === 'semi' || color === 'default'
                          }]" />

                </span>
                <span class="block border-gray-400 lg:border-b-0" :class="{ 'border-b': !visible }"></span>
            </button>
        </slot>
        <collapse-transition>
            <div v-show="visible" :class="showClass">
                <slot />
            </div>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import Icon from './base/Icon.vue';

@Component({
    components: {
        Icon,
        CollapseTransition
    }
})
export default class Accordion extends Vue {
    @Prop({ default: '' }) title: string;
    @Prop({ default: false }) open!: boolean;
    @Prop({ default: false }) location: boolean;
    @Prop({ default: '' }) color: string;
    @Prop({ default: '' }) group: string;
    @Prop({ default: false, type: Boolean }) inset: boolean;
    @Prop({ default: 'px-9 py-5 border-b-4 border-berry-600' }) showClass: string;
    @Prop({ default: '-mx-9 lg:mx-auto' }) compClass: string;
    @Prop({ default: 'default' }) styling: string;
    visible = false;

    created() {
        this.$root.$on('accordion-open', this.onAccordionOpened);
        window.addEventListener('hashchange', this.checkHash);
        this.visible = this.open;
    }

    mounted() {
        this.checkHash();
    }

    beforeDestroy() {
        this.$root.$off('accordion-open', this.onAccordionOpened);
    }

    checkHash() {
        if (this.$attrs.id && this.$attrs.id.length && `#${this.$attrs.id}` === location.hash && !this.visible) {
            setTimeout(() => {
                this.toggle();
            }, 1000);
        }
    }

    onAccordionOpened(group) {
        if (this.group === '') {
            return;
        }
        if (this.group === group) {
            this.visible = false;
        }
    }

    toggle() {
        if (!this.visible) {
            this.$root.$emit('accordion-open', this.group);
        }
        this.visible = !this.visible;
    }

    get lightTextColor() {
        return 'text-white';
    }

    get darkTextColor() {
        return 'text-black';
    }
}
</script>
