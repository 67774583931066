<template>
    <div class="flex flex-col h-max">
        <a :href="item.link"
           :aria-label="item.title"
           :target="item.link_target"
           class="flex flex-col">
            <div class="-mx-4 mb-5 lg:mx-0 lg:cursor-none"
                 @mouseover="hideCursor"
                 @mouseleave="showCursor">
                <div class="relative bg-gray-600"
                     :class="[square ? 'pt-full' : 'pt-5/6']">
                    <img v-if="item.image" :src="item.image"
                         class="absolute w-full h-full object-cover left-0 top-0">
                </div>
            </div>
            <div
                class="text-gray-600 lg:h-6 lg:mb-3 lg:flex lg:flex-row-reverse lg:justify-between lg:space-y-0 uppercase"
                :class="{ 'space-y-4': item.date && item.author, 'mb-3': item.date || item.author }">
                <div>
                    <div v-if="item.date">{{ item.date | date }}</div>
                </div>
                <div class="flex items-center">
                    <template v-if="item.author">
                        <div class="w-5 h-5 bg-gray-200 mr-2 -mt-0.5 relative">
                            <img v-if="item.author.image" :src="item.author.image"
                                 class="absolute w-full h-full object-cover left-0 top-0">
                        </div>
                        <div>{{ item.author.name }}</div>
                    </template>
                </div>
            </div>
            <div class="text-black text-2xl mb-5"
                 v-html="item.title"></div>
        </a>
        <div class="flex -ml-2 flex-wrap text-sm">
            <div
                v-for="cat in filteredCategories"
                :key="item.id + '#' + cat.name"
                @click="toggleCategoryAndScrollToTop(cat.name, cat.parent)"
                :class="catStyle(cat.name)"
                class="click-tag px-2 py-0.5 ml-2 mb-2 cursor-pointer border transition-colors">
                {{ cat.label }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { ResultItem } from '../overview/AsyncOverview.vue';

@Component({})
export default class BlogOverviewItem extends Vue {
    @Prop({ required: true }) item: ResultItem;
    @Prop({ default: false }) square: boolean;

    @Inject({ default: () => undefined }) showCursor: () => void;
    @Inject({ default: () => undefined }) hideCursor: () => void;
    @Inject({ default: () => () => false }) isSelected: (categoryKey: string) => boolean;
    @Inject({ default: () => () => undefined }) toggleCategory: (categoryKey: string, parent?: string, remove?: boolean) => void;
    @Inject({ default: () => () => undefined }) scrollToTop: () => void;

    toggleCategoryAndScrollToTop(categoryKey: string, parent: string) {
        this.toggleCategory(categoryKey, parent);
        this.scrollToTop();
    }

    catStyle(categoryKey: string): string {
        return 'border-black hover:bg-black hover:text-white' + (this.isSelected(categoryKey) ? ' bg-black text-white' : '');
    }

    get filteredCategories() {
        const filtered = this.item.categories.filter(cat => cat.parent !== 'Year');
        return filtered.slice(0, 5);
    }
}
</script>
