<template>
    <form :id="id" :method="method" action="" :enctype="enctype" v-async-form novalidate>
        <slot :captcha-solved="captchaSolved" :on-captcha-solved="onCaptchaSolved" />
    </form>
</template>

<script lang="ts">

export default {
    name: 'GftForm',
    props: {
        id: String,
        method: {
            type: String,
            default: 'post'
        },
        action: String,
        enctype: String
    },
    data() {
        return {
            captchaSolved: false
        };
    },
    methods: {
        onCaptchaSolved(success: boolean) {
            this.captchaSolved = success;
        }
    }
};
</script>
