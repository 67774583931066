<template>
    <div
        ref="element"
        class="transition-opacity duration-500 lg:col-span-9 lg:col-start-4 lg:row-start-1"
        :class="{ 'lg:-z-10': !isActive }">
        <!-- accordion button for mobile -->
        <button v-if="isMobile" class="w-full text-left focus:outline-none" @click="toggleItem">
            <span :class="{ 'text-gray-600 border-b': !isActive }"
                  class="flex justify-between items-center pt-12 pb-6">
                <span class="block w-full">
                    <span class="flex items-center justify-between uppercase tracking-wider">
                        {{ title }}
                        <icon :class="[contrast ? 'text-white' : 'text-black']"
                              :name="[ isActive ? 'minus' : 'plus']"
                              class="shrink-0 ml-auto w-5 h-5 fill-current" />
                    </span>
                </span>
            </span>
        </button>
        <!-- content -->
        <collapse-transition>
            <div v-show="isActive || !isMobile"
                :class="{'bg-black text-white' : contrast, 'text-black': !contrast}"
                class="-mx-9 px-9 py-10 lg:mx-0 lg:px-0 lg:grid transition-all lg:grid-cols-9 lg:gap-x-24 lg:items-center">
                <div class="relative lg:col-span-6 lg:h-160 lg:py-0 lg:flex lg:items-center"
                     :class="{ 'h-[22rem]': rendition === 'portrait', 'h-96 py-4': rendition === 'reel', 'py-10': rendition !== 'reel' }">
                    <div v-if="rendition" class="absolute grid" :class="{
                               'bg-gray-200': !contrast,
                               'bg-gray-800': contrast,
                               'w-1/2 md:w-1/6 h-2/3 top-1/6 -right-9 md:right-1/3 lg:w-full lg:left-0 lg:right-0 xl:right-1/6': rendition === 'reel',
                               'w-1/2 md:w-1/3 h-full top-1/2 -translate-y-1/2 right-1/6 md:right-1/4 lg:-bottom-1/12 lg:right-1/12 lg:w-1/2' : rendition === 'landscape',
                               'w-1/2 -right-9 md:right-1/4 top-0 h-full lg:right-0 lg:w-9/12 lg:-top-1/12': rendition === 'portrait'
                            }"></div>
                    <div class="grid relative z-10 w-full"
                         :class="[rendition === 'landscape' && type === 'image' ? 'h-0 pt-16/9' : 'h-full']">
                        <div v-if="(type !== 'image' || !type)"
                             :key="`video-${uuid}`"
                             class="relative h-0 pt-16/9 top-0 left-0 my-auto">
                            <gft-video v-if="type === 'vimeoLink'"
                                       :key="`vimeo-${uuid}`"
                                       :overlay="overlay"
                                       :link="media"
                                       :edit-mode="isEditMode"
                                       :image="thumbnail"
                                       class="object-contain absolute w-full h-full top-0 left-0 object-left"></gft-video>
                            <template v-if="type === 'youtubeId'">
                                <iframe v-if="isEditMode"
                                        class="cookieconsent-optin-marketing object-contain absolute w-full h-full top-0 left-0 object-left"
                                        :src="`https://www.youtube.com/embed/${media}?playsinline=1&showinfo=0&rel=0`"
                                        frameborder="0"
                                        data-cookieconsent="marketing"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                <iframe v-else
                                        class="cookieconsent-optin-marketing object-contain absolute w-full h-full top-0 left-0 object-left"
                                        :data-cookieblock-src="`https://www.youtube.com/embed/${media}?playsinline=1&showinfo=0&rel=0`"
                                        frameborder="0"
                                        data-cookieconsent="marketing"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                <div v-if="!isEditMode" class="cookieconsent-optout-marketing absolute left-0 top-1/2 w-full -translate-y-1/2 pt-16/9">
                                    <div class="absolute w-full h-full top-0 left-0">
                                        <img v-if="thumbnail" :alt="thumbnailAlt" :src="thumbnail" class="absolute w-full h-full top-0 left-0 object-cover object-center">
                                        <div class="absolute bg-black/40 w-full h-full top-0 left-0"></div>
                                    </div>
                                    <div class="absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                                        <div class="text-xl lg:text-2xl text-center py-8 lg:py-18">
                                            {{ cookieOverlay }}
                                        </div>
                                    </div>
                                    <div class="absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                        <div class="flex justify-center items-center w-full h-full rounded-full">
                                            <a href="javascript:Cookiebot.renew()" :aria-label="cookieOverlay" class="flex justify-center items-center w-16 h-16 rounded-full border border-white cursor-pointer lg:w-36 lg:h-36">
                                                <icon name="arrow-right-outline"
                                                      class="w-11 h-11 fill-current text-white" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <img v-else-if="media && type === 'image'"
                             :key="`mobile-img-${uuid}`"
                             :class="{'h-full left-0 w-full object-start top-0 lg:object-left': rendition === 'reel',
                                   'object-center place-self-center h-full lg:top-0 lg:object-left': rendition === 'landscape',
                                   'object-center width-full h-full place-self-center lg:-top-1/12 lg:py-6 lg:-left-1/12 xl:left-0 lg:object-left': rendition === 'portrait'}"
                             :src="media"
                             :alt="mediaAlt"
                             class=" grid object-contain absolute transition-opacity duration-500 lg:w-full lg:left-0">
                    </div>
                </div>
                <div class="lg:col-span-3">
                    <slot />
                </div>
            </div>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import { Component, Inject, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { CollapseTransition, Icon }
})
export default class Case extends Vue {
    @Inject({
        default: () => null
    }) readonly registerCase!: Function;

    @Prop() uuid: string;
    @Prop() linkStyle: string;
    @Prop() rendition: string;
    @Prop() title: string;
    @Prop() media: string;
    @Prop({ default: '' }) mediaAlt: string;
    @Prop() type: string;
    @Prop() overlay: string;
    @Prop() cookieOverlay: string;
    @Prop() linkLabel: string;
    @Prop() linkUrl: string;
    @Prop() text: string;
    @Prop({ default: false }) contrast: boolean;
    @Prop({ default: '' }) group: string;
    @Prop({ default: '' }) thumbnail: string;
    @Prop({ default: '' }) thumbnailAlt: string;
    @Prop({ default: false }) isEditMode: boolean;

    isActive = false;
    isMobile = window.innerWidth < 1024;

    mounted() {
        this.registerCase(this);
        window.addEventListener('resize', () => { this.isMobile = window.innerWidth < 1200; });
    }

    created() {
        this.$root.$on('accordion-open', this.onAccordionOpened);
    }

    beforeDestroy() {
        this.$root.$off('accordion-open', this.onAccordionOpened);
        window.removeEventListener('resize', () => { this.isMobile = window.innerWidth < 1200; });
    }

    onAccordionOpened(group) {
        if (this.group === '') {
            return;
        }
        if (this.group === group) {
            this.isActive = false;
        }
    }

    toggleItem() {
        if (!this.isActive) {
            this.$root.$emit('accordion-open', this.group);
        }
        this.isActive = !this.isActive;
    }
}
</script>
