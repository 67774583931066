<template>
    <div>
        <!-- List for desktop -->
        <div class="hidden lg:block lg:col-span-3 lg:row-start-1">
            <div class="z-10 col-span-3 col-start-1 row-start-1 items-center pb-10 self-center justify-self-start w-full">
                <div v-for="useCase in useCases"
                     :key="useCase.uuid"
                     :class="[useCase.isActive ? '-mr-4 pr-12' : 'text-gray-600 mr-8']"
                     class="flex justify-between py-4 border-b border-current cursor-pointer transition-all duration-500"
                     @mouseenter="hover(useCase)">
                    <h3 class="pr-4 line-clamp-2 text-inherit uppercase tracking-wider">{{ useCase.title }}</h3>
                </div>
            </div>
        </div>
        <!-- Items -->
        <slot name="items"></slot>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import Icon from './base/Icon.vue';
import GftVideo from './GftVideo.vue';

@Component({ components: { Icon, GftVideo } })
export default class UseCases extends Vue {
    @Provide('registerCase') childRegistration = this.registerCase;
    @Prop({ default: false }) contrast: boolean;
    @Prop() overlay: string;
    @Prop() cookieOverlay: string;
    @Prop({ default: false }) isEditMode: boolean;

    useCases = [];

    get active() {
        const useCase = this.useCases.find(c => c.isActive);
        if (useCase) return useCase;
        if (this.useCases[0] && !this.useCases[0].isMobile) {
            this.useCases[0].isActive = true;
            return this.useCases[0];
        }
        return null;
    }

    registerCase(useCase) {
        this.useCases.push(useCase);
        if (this.$children.filter(this.filterCases).length === this.useCases.length) {
            if (this.useCases.length > 0) {
                this.$nextTick(() => {
                    this.useCases[0].toggleItem();
                });
            }
        }
    }

    hover(useCase) {
        this.useCases.forEach(c => {
            c.isActive = false;
        });
        useCase.isActive = true;
    }

    filterCases(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'case';
    }
}
</script>
