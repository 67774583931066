<template>
    <div class="overflow-y-auto relative flex-1 scrollbar-hide">
        <div class="container mt-16" v-if="results.length === 0">{{ noResultsMessage }}</div>
        <a v-for="(result, r) in results"
           :aria-labelledby="result.title"
           :key="`sr#${r}`"
           class="container flex items-center mb-12 lg:mb-5 group"
           :href="result.link">
            <div class="hidden overflow-hidden relative flex-none w-60 lg:block">
                <div class="h-0 pt-5/3">
                    <img :src="result.image ? `${result.image}` : placeholderImage"
                         :alt="result.imageAlt ? result.imageAlt : 'result-image'"
                         class="object-cover object-center absolute top-0 left-0 w-full h-full">
                </div>
            </div>
            <div class="lg:px-20">
                <h3 class="mb-2 text-2xl pr-1.5 text-inherit"
                    v-html="result.title"></h3>
                <div class="line-clamp-2 pr-1.5"
                     v-html="result.text"></div>
            </div>
            <div
                :class="[{ 'border-berry-600': inOverlay }]"
                class="rounded-full inline-flex shrink-0 justify-center items-center ml-auto w-14 h-14 bg-transparent border transition-colors group-hover:bg-berry-600 group-hover:border-berry-600 group-hover:text-white">
                <icon name="arrow" class="w-8 h-8 transition-colors fill-current" />
            </div>
        </a>
        <slot />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
export default class SearchResults extends Vue {
    @Prop({ default: () => [] }) results: {
        image: string;
        imageAlt: string;
        title: string;
        text: string;
        link: string;
    }[];

    @Prop({ required: true }) noResultsMessage: string;
    @Prop({ default: false, type: Boolean }) inOverlay: boolean;

    placeholderImage = `${this.$contextPath}/.resources/gft/webresources/img/search-placeholder.webp`;
}
</script>
