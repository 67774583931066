import Vue from 'vue';
import './components/Dummy';
import './components/base/DeferredScript';
import './components/Faq';
import './components/ContactBar';
import './components/GftTicker';
import './components/navigation/MainNavigation';
import './components/navigation/NavigationLanguages';
import './components/navigation/SiteNavigation';
import './components/GftInfiniteAutoplay';
import './components/GftSwiper';
import './components/GftSwiperSlide';
import './components/GftVideo';
import './components/Readmore';
import './components/ReportsTable';
import './components/ContentNavigation';
import './components/teaser/HeroTextMediaColumns';
import './components/teaser/HeroTextMediaColumnsItem';
import './components/teaser/ZoomText';
import './components/GatedDownload';
import './components/StockChart';
import './components/ClientOverview';
import './components/Client';
import './components/overview/OverviewComponents';
import BlogIncrement from './components/blog/BlogIncrement.vue';
import Dropdown from './components/Dropdown.vue';
import DropdownItem from './components/DropdownItem.vue';
import ContactForm from './components/ContactForm.vue';
import AutoCompleteInput from './components/base/AutoCompleteInput.vue';
import SwiperProgressBar from './components/GftSwiperProgressBar.vue';
import EmailInput from './components/base/EmailInput.vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import UseCasePopup from './components/UseCasePopup.vue';
import ShareButton from './components/ShareButton.vue';
import ShareList from './components/ShareList.vue';
import FixedScrollButton from './components/FixedScrollButton.vue';
import BlogFilterMenu from './components/blog/BlogFilterMenu.vue';
import BlogAccordion from './components/blog/BlogAccordion.vue';
import BlogOverviewItem from './components/blog/BlogOverviewItem.vue';
import BlogNext from './components/blog/BlogNext.vue';
import GftFootnote from './components/base/GftFootnote.vue';
import Accordion from './components/Accordion.vue';
import UseCases from './components/UseCases.vue';
import ContentNavigationItem from './components/ContentNavigationItem.vue';
import GftSearch from './components/GftSearch.vue';
// import GftGoogleSearch from './components/GftGoogleSearch.vue';
import Case from './components/Case.vue';
import FriendlyCaptcha from './components/base/FriendlyCaptcha.vue';
import GftForm from './components/base/GftForm.vue';

Vue.component('dropdown', Dropdown);
Vue.component('dropdown-item', DropdownItem);
Vue.component('contact-form', ContactForm);
Vue.component('autocomplete-input', AutoCompleteInput);
Vue.component('gft-progress-bar', SwiperProgressBar);
Vue.component('email-input', EmailInput);
Vue.component('collapse-transition', CollapseTransition);
Vue.component('use-case-popup', UseCasePopup);
Vue.component('share-button', ShareButton);
Vue.component('share-list', ShareList);
Vue.component('blog-increment', BlogIncrement);
Vue.component('fixed-scroll-button', FixedScrollButton);
Vue.component('blog-filter-menu', BlogFilterMenu);
Vue.component('blog-accordion', BlogAccordion);
Vue.component('blog-overview-item', BlogOverviewItem);
Vue.component('accordion', Accordion);
Vue.component('blog-next', BlogNext);
Vue.component('gft-footnote', GftFootnote);
Vue.component('use-cases', UseCases);
Vue.component('content-navigation-item', ContentNavigationItem);
Vue.component('gft-search', GftSearch);
// Vue.component('gft-google-search', GftGoogleSearch);
Vue.component('case', Case);
Vue.component('friendly-captcha', FriendlyCaptcha);
Vue.component('gft-form', GftForm);
